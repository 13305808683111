import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Skannu - Compare Forex or Travel Websites" />
    </Layout>
  )
}

export default IndexPage
